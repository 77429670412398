
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import AddEmployerModal from "./AddEmployerModal.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import { api } from "@/api/api";
import { useRouter } from "@/shared/useHelpers";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";

export default defineComponent({
  name: "CourseSectionEmployer",
  components: { AddEmployerModal, BaseModal, BaseConfirmModalForm },
  setup() {
    const modalData = ref(getInitialModalData());
    const modalDataEdit = ref(getInitialModalData());
    const inactivateModalData = ref(getInitialModalData());
    const router = useRouter();

    const employers = ref<any[]>([]);

    const openAddEmployer = useOpenModal(ModalType.Add, "", modalData);
    const openEditEmployer = useOpenModal(ModalType.Edit, "", modalDataEdit);

    const closeModal = () => {
      modalData.value.showModal = false;
      modalDataEdit.value.showModal = false;
    };

    const inactivateEmployers = async () => {
      await api.course.inactivateCourseEmploymentOnUser(+router.currentRoute.params.id);
      inactivateModalData.value.showModal = false;
      await getEmployers();
    };

    onMounted(() => {
      getEmployers();
    });

    const getEmployers = async () => {
      const response = await api.course.getCourseParticipantEmployment(+router.currentRoute.params.id);
      employers.value = response.data.map((x) => ({ employmentId: x.id, ...x.employment }));
    };

    const headers = [
      { text: "Id", value: "employmentId" },
      {
        text: "Handlinger",
        value: "action",
      },
      { text: "Navn", value: "name" },
      {
        text: "Epost",
        value: "email",
      },
      { text: "Adresse", value: "address" },
      {
        text: "Poststed",
        value: "city",
      },
      {
        text: "Telefonnummer",
        value: "mobilePhone",
      },
      {
        text: "Postnummer",
        value: "postalCode",
      },
      {
        text: "Stillingsprosent",
        value: "employmentFraction",
      },
      {
        text: "Nåværende arbeidsgiver",
        value: "isCurrentEmployment",
      },
    ];

    return {
      modalData,
      isMobile,
      openAddEmployer,
      closeModal,
      headers,
      openEditEmployer,
      employers,
      modalDataEdit,
      getEmployers,
      inactivateEmployers,
      inactivateModalData,
      showInactivateEmployerModal: useOpenModal(ModalType.Display, "Inaktiver arbeidsgivere", inactivateModalData),
    };
  },
});
