
import { api } from "@/api/api";
import {
  ApiApplicationFormTemplateAdditionalQuestionDto,
  ApiGetApplicationFormResponseDto,
  ApiGetApplicationFormTemplateDto,
  ApiGetCustomerDto,
  ApiGetMemberOrganizationDto,
} from "@/api/generated/Api";
import BaseInfoField from "@/components/shared/field/BaseInfoField.vue";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { QuestionValueType } from "@/shared/types/questions.types";
import { countriesList } from "@/shared/utils/countriesList";
import { computed, defineComponent, onMounted, PropType, ref, watch } from "@vue/composition-api";

interface CountryItem {
  name: string;
  ssbCode: string;
}

interface QuestionAnswer {
  question: string;
  answer?: string | null;
  isVisible: boolean;
}

interface ApplicationFieldObject {
  value: string | null | undefined;
  label: string;
}

type Country = CountryItem[];

export default defineComponent({
  components: { BaseInfoField },
  name: "CourseWebApplication",
  props: {
    contact: {
      type: Object as PropType<ApiGetCustomerDto>,
      required: true,
    },
    applicationInfo: {
      type: Object as PropType<ApiGetApplicationFormResponseDto>,
      required: true,
    },
    applicationForm: {
      type: Object as PropType<ApiGetApplicationFormTemplateDto>,
      required: true,
    },
  },
  setup(props) {
    const contact = props.contact;
    const applicationInfo = props.applicationInfo;
    const applicationForm = props.applicationForm;

    const getOrganizationsFromAPI = ref();
    const applicationFields = ref<ApplicationFieldObject[]>();

    const questionAnswers = ref<QuestionAnswer[]>();
    const applicationFormQuestions = ref<ApiApplicationFormTemplateAdditionalQuestionDto[]>();

    const visibleQuestions = computed(() => questionAnswers.value?.filter((item) => item.isVisible && item.answer));

    const showCostBearerSection = computed(
      () => applicationInfo.isCostBearerPrivate !== undefined && applicationInfo.isCostBearerPrivate !== null
    );
    const showCostBearerPrivate = computed(() => applicationInfo.isCostBearerPrivate);
    const showCostBearerNonPrivate = computed(() => applicationInfo.isCostBearerPrivate === false);

    onMounted(async () => {
      await getOrganizations();
      applicationFormQuestions.value = applicationForm.additionalQuestions ?? undefined;
      questionAnswers.value = applicationFormQuestions.value?.map(({ id, description, type }) => ({
        id,
        question: description ?? "-",
        answer: getQuestionAnswerById(id),
        isVisible: type !== QuestionValueType.File,
      }));
    });

    const getOrganizations = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        getOrganizationsFromAPI.value = (await api.organization.getMemberOrganizationsAsync()).data;
      });
    };

    const getQuestionAnswerById = (questionId: string) => {
      const answer = applicationInfo.additionalQuestions?.find(
        (question) => question.questionId === questionId
      )?.response;
      // every answer returns as string
      if (answer === "false") {
        return "Nei";
      }
      if (answer === "true") {
        return "Ja";
      }
      return answer;
    };

    watch(
      () => getOrganizationsFromAPI.value,
      (to) => {
        applicationFields.value = [
          {
            value: formatDate(applicationInfo.inserted ?? "-"),
            label: "Søknadsdato",
          },
          {
            value: getCustomStringByBoolean(applicationForm.isNewsletter, applicationInfo.isNewsletter),
            label: "Ønsker nyhetsbrev",
          },
          {
            value: getCustomStringByBoolean(
              applicationForm.isCompetence,
              applicationInfo.isFormalCompetence,
              "Formell kompetanse",
              "Realkompetanse"
            ),
            label: "Kompetanse",
          },
          {
            value: getMemberOrganizationName(applicationInfo.memberOrganizationId ?? 0, to),
            label: "Medlemsorganisasjon",
          },
          {
            value: isRegionalOffice(applicationInfo.memberOrganizationId ?? 0, to),
            label: "Regionkontor",
          },
        ].filter((item) => item.value);
      },
      { deep: true }
    );

    const getOrganizationById = (orgNumber: number, memberOrganizations: ApiGetMemberOrganizationDto[]) =>
      memberOrganizations.find((org) => org.id === +orgNumber);

    const getMemberOrganizationName = (orgNumber: number, memberOrganizations: ApiGetMemberOrganizationDto[]) => {
      if (!applicationForm.isMemberOrganization) {
        return undefined;
      }
      return getOrganizationById(orgNumber, memberOrganizations)?.name ?? "Ingen medlemsorganisasjon";
    };

    const isRegionalOffice = (orgNumber: number, memberOrganizations: ApiGetMemberOrganizationDto[]) => {
      if (!applicationForm.isMemberOrganization) {
        return undefined;
      }
      return getOrganizationById(orgNumber, memberOrganizations)?.parentId ? "Ja" : "Nei";
    };

    const getCountryName = (countryCode: string, countriesList: Country) => {
      if (!applicationForm.isCitizenship) {
        return undefined;
      }
      const country = countriesList.find((countries) => countries.ssbCode === countryCode);
      return country?.name ?? "Ikke valgt";
    };

    const getCustomStringByBoolean = (
      appFormBool: boolean,
      appInfoBool?: boolean | null,
      trueStr = "Ja",
      falseStr = "Nei"
    ) => {
      if (!appFormBool) {
        return undefined;
      }
      return appInfoBool ? trueStr : falseStr;
    };

    const installmentTypeLabelGenerator: Record<number, () => string> = {
      0: () => "Engangsbetaling",
      1: () => `Avdragsbetaling - ${getInstallmentInterval()}`,
      2: () => "Prosentsbetaling",
    };

    const getInstallmentType = () => {
      const installmentType = applicationInfo.costBearerPrivate?.installmentType;
      if (!installmentType) {
        return undefined;
      }
      return installmentTypeLabelGenerator[installmentType]?.();
    };

    const installmentIntervals: Record<number, string> = {
      1: "Månedlig",
      3: "Kvartalsvis",
      6: "Halvårlig",
    };

    const getInstallmentInterval = () => {
      const installmentInterval = applicationInfo.costBearerPrivate?.installmentInterval;
      if (!installmentInterval) {
        return undefined;
      }
      return installmentIntervals[installmentInterval];
    };

    const personalFields = ref(
      [
        {
          value: getCountryName(applicationInfo.citizenship ?? "", countriesList),
          label: "Statsborgerskap",
        },
        {
          value: applicationInfo.personalNumber,
          label: "Fødselsnummer",
        },
        {
          value: getCustomStringByBoolean(
            applicationForm.isNorwegianMotherTongue,
            applicationInfo.isNorwegianMotherTongue,
            "Norsk",
            "Annet"
          ),
          label: "Morsmål",
        },
        {
          value: applicationInfo.norwegianSkillsLevel,
          label: "Norskferdigheter",
        },
        {
          value: applicationInfo.norwegianSkillsCerfLevel,
          label: "Norskferdigheter, CEFR-nivå",
        },
      ].filter((item) => item.value)
    );

    const employerFields = ref(
      [
        {
          value: applicationInfo.employer?.name,
          label: "Navn",
        },
        {
          value: applicationInfo.employer?.address,
          label: "Adresse",
        },
        {
          value: applicationInfo.employer?.zip,
          label: "Postnummer",
        },
        {
          value: applicationInfo.employer?.city,
          label: "Poststed",
        },
        {
          value: applicationInfo.employer?.email,
          label: "E-post til nærmeste overordnet",
        },
      ].filter((item) => item.value)
    );

    const costBearerPrivateFields = ref(
      [
        {
          value: `${contact.customer?.firstName} ${contact.customer?.lastName}`,
          label: "Navn",
        },
        {
          value: contact.postAddress1,
          label: "Adresse",
        },
        {
          value: contact.postZip,
          label: "Postnummer",
        },
        {
          value: contact.postCity,
          label: "Poststed",
        },
        {
          value: contact.mobileNumber,
          label: "Mobilnummer",
        },
        {
          value: contact.email,
          label: "E-post",
        },
        {
          value: getInstallmentType(),
          label: "Betalingsmåte",
        },
      ].filter((item) => item.value)
    );

    const purchaserFields = ref(
      [
        {
          value: applicationInfo.purchaser?.name,
          label: "Organisasjonsnavn",
        },
        {
          value: applicationInfo.purchaser?.organizationNumber,
          label: "Organisasjonsnummer",
        },
        {
          value: applicationInfo.purchaser?.address,
          label: "Adresse",
        },
        {
          value: applicationInfo.purchaser?.zip,
          label: "Postnummer",
        },
        {
          value: applicationInfo.purchaser?.city,
          label: "Poststed",
        },
        {
          value: applicationInfo.purchaser?.mobileNumber,
          label: "Mobilnummer",
        },
        {
          value: applicationInfo.purchaser?.email,
          label: `E-post${applicationInfo.costBearerOrganization?.isPurchaser ? " for fakturamottak" : ""}`,
        },
        {
          value: applicationInfo.costBearerOrganization?.isPurchaser
            ? applicationInfo.costBearerOrganization?.orderNumber
            : undefined,
          label: "Bestillingsnummer",
        },
        {
          value: applicationInfo.costBearerOrganization?.isPurchaser
            ? applicationInfo.costBearerOrganization?.referenceNumber
            : undefined,
          label: "Deres referanse",
        },
      ].filter((item) => item.value)
    );

    const costBearerOrganizationFields = ref(
      [
        {
          value: applicationInfo.costBearerOrganization?.name,
          label: "Organisasjonsnavn",
        },
        {
          value: applicationInfo.costBearerOrganization?.organizationNumber,
          label: "Organisasjonsnummer",
        },
        {
          value: applicationInfo.costBearerOrganization?.address,
          label: "Adresse",
        },
        {
          value: applicationInfo.costBearerOrganization?.zip,
          label: "Postnummer",
        },
        {
          value: applicationInfo.costBearerOrganization?.city,
          label: "Poststed",
        },
        {
          value: applicationInfo.costBearerOrganization?.mobileNumber,
          label: "Mobilnummer",
        },
        {
          value: applicationInfo.costBearerOrganization?.email,
          label: "E-post for fakturamottak",
        },
        {
          value: applicationInfo.costBearerOrganization?.orderNumber,
          label: "Bestillingsnummer",
        },
        {
          value: applicationInfo.costBearerOrganization?.referenceNumber,
          label: "Deres referanse",
        },
      ].filter((item) => item.value)
    );

    return {
      personalFields,
      employerFields,
      costBearerPrivateFields,
      purchaserFields,
      costBearerOrganizationFields,
      applicationFields,
      questionAnswers,
      visibleQuestions,
      showCostBearerSection,
      showCostBearerPrivate,
      showCostBearerNonPrivate,
    };
  },
});
