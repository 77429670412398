export const countriesList = [
  {
    name: "Norge",
    ssbCode: "000",
  },
  {
    name: "Danmark",
    ssbCode: "101",
  },
  {
    name: "Grønland",
    ssbCode: "102",
  },
  {
    name: "Finland",
    ssbCode: "103",
  },
  {
    name: "Færøyene",
    ssbCode: "104",
  },
  {
    name: "Island",
    ssbCode: "105",
  },
  {
    name: "Sverige",
    ssbCode: "106",
  },
  {
    name: "Albania",
    ssbCode: "111",
  },
  {
    name: "Belgia",
    ssbCode: "112",
  },
  {
    name: "Bulgaria",
    ssbCode: "113",
  },
  {
    name: "Andorra",
    ssbCode: "114",
  },
  {
    name: "Estland",
    ssbCode: "115",
  },
  {
    name: "Frankrike",
    ssbCode: "117",
  },
  {
    name: "Gibraltar",
    ssbCode: "118",
  },
  {
    name: "Hellas",
    ssbCode: "119",
  },
  {
    name: "Hviterussland",
    ssbCode: "120",
  },
  {
    name: "Irland",
    ssbCode: "121",
  },
  {
    name: "Kroatia",
    ssbCode: "122",
  },
  {
    name: "Italia",
    ssbCode: "123",
  },
  {
    name: "Latvia",
    ssbCode: "124",
  },
  {
    name: "Malta",
    ssbCode: "126",
  },
  {
    name: "Nederland",
    ssbCode: "127",
  },
  {
    name: "Liechtenstein",
    ssbCode: "128",
  },
  {
    name: "Luxembourg",
    ssbCode: "129",
  },
  {
    name: "Monaco",
    ssbCode: "130",
  },
  {
    name: "Polen",
    ssbCode: "131",
  },
  {
    name: "Portugal",
    ssbCode: "132",
  },
  {
    name: "Romania",
    ssbCode: "133",
  },
  {
    name: "San Marino",
    ssbCode: "134",
  },
  {
    name: "Litauen",
    ssbCode: "136",
  },
  {
    name: "Spania",
    ssbCode: "137",
  },
  {
    name: "Moldova",
    ssbCode: "138",
  },
  {
    name: "Storbritannia",
    ssbCode: "139",
  },
  {
    name: "Russland",
    ssbCode: "140",
  },
  {
    name: "Sveits",
    ssbCode: "141",
  },
  {
    name: "Tyrkia",
    ssbCode: "143",
  },
  {
    name: "Tyskland",
    ssbCode: "144",
  },
  {
    name: "Slovenia",
    ssbCode: "146",
  },
  {
    name: "Ukraina",
    ssbCode: "148",
  },
  {
    name: "Ungarn",
    ssbCode: "152",
  },
  {
    name: "Østerrike",
    ssbCode: "153",
  },
  {
    name: "Vatikanstaten",
    ssbCode: "154",
  },
  {
    name: "Bosnia-Hercegovina",
    ssbCode: "155",
  },
  {
    name: "Nord-Makedonia",
    ssbCode: "156",
  },
  {
    name: "Slovakia",
    ssbCode: "157",
  },
  {
    name: "Tsjekkia",
    ssbCode: "158",
  },
  {
    name: "Serbia",
    ssbCode: "159",
  },
  {
    name: "Montenegro",
    ssbCode: "160",
  },
  {
    name: "Kosovo",
    ssbCode: "161",
  },
  {
    name: "Guernsey",
    ssbCode: "162",
  },
  {
    name: "Jersey",
    ssbCode: "163",
  },
  {
    name: "Man",
    ssbCode: "164",
  },
  {
    name: "Algerie",
    ssbCode: "203",
  },
  {
    name: "Angola",
    ssbCode: "204",
  },
  {
    name: "Botswana",
    ssbCode: "205",
  },
  {
    name: "St. Helena",
    ssbCode: "209",
  },
  {
    name: "Det britiske territoriet i Indiahavet",
    ssbCode: "213",
  },
  {
    name: "Burundi",
    ssbCode: "216",
  },
  {
    name: "Komorene",
    ssbCode: "220",
  },
  {
    name: "Benin",
    ssbCode: "229",
  },
  {
    name: "Ekvatorial-Guinea",
    ssbCode: "235",
  },
  {
    name: "Elfenbeinskysten",
    ssbCode: "239",
  },
  {
    name: "Eritrea",
    ssbCode: "241",
  },
  {
    name: "Etiopia",
    ssbCode: "246",
  },
  {
    name: "Egypt",
    ssbCode: "249",
  },
  {
    name: "Djibouti",
    ssbCode: "250",
  },
  {
    name: "Gabon",
    ssbCode: "254",
  },
  {
    name: "Gambia",
    ssbCode: "256",
  },
  {
    name: "Ghana",
    ssbCode: "260",
  },
  {
    name: "Guinea",
    ssbCode: "264",
  },
  {
    name: "Guinea-Bissau",
    ssbCode: "266",
  },
  {
    name: "Kamerun",
    ssbCode: "270",
  },
  {
    name: "Kapp Verde",
    ssbCode: "273",
  },
  {
    name: "Kenya",
    ssbCode: "276",
  },
  {
    name: "Kongo-Brazzaville",
    ssbCode: "278",
  },
  {
    name: "Kongo",
    ssbCode: "279",
  },
  {
    name: "Lesotho",
    ssbCode: "281",
  },
  {
    name: "Liberia",
    ssbCode: "283",
  },
  {
    name: "Libya",
    ssbCode: "286",
  },
  {
    name: "Madagaskar",
    ssbCode: "289",
  },
  {
    name: "Malawi",
    ssbCode: "296",
  },
  {
    name: "Mali",
    ssbCode: "299",
  },
  {
    name: "Marokko",
    ssbCode: "303",
  },
  {
    name: "Vest-Sahara",
    ssbCode: "304",
  },
  {
    name: "Mauritania",
    ssbCode: "306",
  },
  {
    name: "Mauritius",
    ssbCode: "307",
  },
  {
    name: "Namibia",
    ssbCode: "308",
  },
  {
    name: "Niger",
    ssbCode: "309",
  },
  {
    name: "Nigeria",
    ssbCode: "313",
  },
  {
    name: "Mosambik",
    ssbCode: "319",
  },
  {
    name: "Mayotte",
    ssbCode: "322",
  },
  {
    name: "Réunion",
    ssbCode: "323",
  },
  {
    name: "Zimbabwe",
    ssbCode: "326",
  },
  {
    name: "Rwanda",
    ssbCode: "329",
  },
  {
    name: "São Tomé og Príncipe",
    ssbCode: "333",
  },
  {
    name: "Senegal",
    ssbCode: "336",
  },
  {
    name: "Den sentralafrikanske republikk",
    ssbCode: "337",
  },
  {
    name: "Seychellene",
    ssbCode: "338",
  },
  {
    name: "Sierra Leone",
    ssbCode: "339",
  },
  {
    name: "Somalia",
    ssbCode: "346",
  },
  {
    name: "Sør-Sudan",
    ssbCode: "355",
  },
  {
    name: "Sudan",
    ssbCode: "356",
  },
  {
    name: "Eswatini",
    ssbCode: "357",
  },
  {
    name: "Sør-Afrika",
    ssbCode: "359",
  },
  {
    name: "Tanzania",
    ssbCode: "369",
  },
  {
    name: "Tsjad",
    ssbCode: "373",
  },
  {
    name: "Togo",
    ssbCode: "376",
  },
  {
    name: "Tunisia",
    ssbCode: "379",
  },
  {
    name: "Uganda",
    ssbCode: "386",
  },
  {
    name: "Zambia",
    ssbCode: "389",
  },
  {
    name: "Burkina Faso",
    ssbCode: "393",
  },
  {
    name: "Afghanistan",
    ssbCode: "404",
  },
  {
    name: "Armenia",
    ssbCode: "406",
  },
  {
    name: "Aserbajdsjan",
    ssbCode: "407",
  },
  {
    name: "Bahrain",
    ssbCode: "409",
  },
  {
    name: "Bangladesh",
    ssbCode: "410",
  },
  {
    name: "Bhutan",
    ssbCode: "412",
  },
  {
    name: "Brunei",
    ssbCode: "416",
  },
  {
    name: "Myanmar",
    ssbCode: "420",
  },
  {
    name: "Sri Lanka",
    ssbCode: "424",
  },
  {
    name: "De forente arabiske emirater",
    ssbCode: "426",
  },
  {
    name: "Filippinene",
    ssbCode: "428",
  },
  {
    name: "Georgia",
    ssbCode: "430",
  },
  {
    name: "Taiwan",
    ssbCode: "432",
  },
  {
    name: "Hongkong",
    ssbCode: "436",
  },
  {
    name: "India",
    ssbCode: "444",
  },
  {
    name: "Indonesia",
    ssbCode: "448",
  },
  {
    name: "Irak",
    ssbCode: "452",
  },
  {
    name: "Iran",
    ssbCode: "456",
  },
  {
    name: "Israel",
    ssbCode: "460",
  },
  {
    name: "Japan",
    ssbCode: "464",
  },
  {
    name: "Jordan",
    ssbCode: "476",
  },
  {
    name: "Kambodsja",
    ssbCode: "478",
  },
  {
    name: "Kasakhstan",
    ssbCode: "480",
  },
  {
    name: "Kina",
    ssbCode: "484",
  },
  {
    name: "Nord-Korea",
    ssbCode: "488",
  },
  {
    name: "Sør-Korea",
    ssbCode: "492",
  },
  {
    name: "Kuwait",
    ssbCode: "496",
  },
  {
    name: "Kypros",
    ssbCode: "500",
  },
  {
    name: "Kirgisistan",
    ssbCode: "502",
  },
  {
    name: "Laos",
    ssbCode: "504",
  },
  {
    name: "Libanon",
    ssbCode: "508",
  },
  {
    name: "Macao",
    ssbCode: "510",
  },
  {
    name: "Malaysia",
    ssbCode: "512",
  },
  {
    name: "Maldivene",
    ssbCode: "513",
  },
  {
    name: "Mongolia",
    ssbCode: "516",
  },
  {
    name: "Oman",
    ssbCode: "520",
  },
  {
    name: "Palestina",
    ssbCode: "524",
  },
  {
    name: "Nepal",
    ssbCode: "528",
  },
  {
    name: "Pakistan",
    ssbCode: "534",
  },
  {
    name: "Øst-Timor",
    ssbCode: "537",
  },
  {
    name: "Qatar",
    ssbCode: "540",
  },
  {
    name: "Saudi-Arabia",
    ssbCode: "544",
  },
  {
    name: "Singapore",
    ssbCode: "548",
  },
  {
    name: "Tadsjikistan",
    ssbCode: "550",
  },
  {
    name: "Turkmenistan",
    ssbCode: "552",
  },
  {
    name: "Usbekistan",
    ssbCode: "554",
  },
  {
    name: "Syria",
    ssbCode: "564",
  },
  {
    name: "Thailand",
    ssbCode: "568",
  },
  {
    name: "Vietnam",
    ssbCode: "575",
  },
  {
    name: "Jemen",
    ssbCode: "578",
  },
  {
    name: "De amerikanske Jomfruøyene",
    ssbCode: "601",
  },
  {
    name: "Barbados",
    ssbCode: "602",
  },
  {
    name: "Antigua og Barbuda",
    ssbCode: "603",
  },
  {
    name: "Belize",
    ssbCode: "604",
  },
  {
    name: "Bahamas",
    ssbCode: "605",
  },
  {
    name: "Bermuda",
    ssbCode: "606",
  },
  {
    name: "De britiske Jomfruøyene",
    ssbCode: "608",
  },
  {
    name: "Canada",
    ssbCode: "612",
  },
  {
    name: "Caymanøyene",
    ssbCode: "613",
  },
  {
    name: "Costa Rica",
    ssbCode: "616",
  },
  {
    name: "Cuba",
    ssbCode: "620",
  },
  {
    name: "Dominica",
    ssbCode: "622",
  },
  {
    name: "Den dominikanske republikk",
    ssbCode: "624",
  },
  {
    name: "Grenada",
    ssbCode: "629",
  },
  {
    name: "Guadeloupe",
    ssbCode: "631",
  },
  {
    name: "Guatemala",
    ssbCode: "632",
  },
  {
    name: "Haiti",
    ssbCode: "636",
  },
  {
    name: "Honduras",
    ssbCode: "644",
  },
  {
    name: "Jamaica",
    ssbCode: "648",
  },
  {
    name: "Martinique",
    ssbCode: "650",
  },
  {
    name: "Mexico",
    ssbCode: "652",
  },
  {
    name: "Montserrat",
    ssbCode: "654",
  },
  {
    name: "Aruba",
    ssbCode: "657",
  },
  {
    name: "Sint Maarten",
    ssbCode: "658",
  },
  {
    name: "Bonaire, Sint Eustatius og Saba",
    ssbCode: "659",
  },
  {
    name: "Anguilla",
    ssbCode: "660",
  },
  {
    name: "Curaçao",
    ssbCode: "661",
  },
  {
    name: "Nicaragua",
    ssbCode: "664",
  },
  {
    name: "Panama",
    ssbCode: "668",
  },
  {
    name: "El Salvador",
    ssbCode: "672",
  },
  {
    name: "Saint-Pierre-et-Miquelon",
    ssbCode: "676",
  },
  {
    name: "Saint Kitts og Nevis",
    ssbCode: "677",
  },
  {
    name: "Saint Lucia",
    ssbCode: "678",
  },
  {
    name: "Saint Vincent og Grenadinene",
    ssbCode: "679",
  },
  {
    name: "Trinidad og Tobago",
    ssbCode: "680",
  },
  {
    name: "Turks- og Caicosøyene",
    ssbCode: "681",
  },
  {
    name: "USA",
    ssbCode: "684",
  },
  {
    name: "Puerto Rico",
    ssbCode: "685",
  },
  {
    name: "Saint-Martin",
    ssbCode: "686",
  },
  {
    name: "Saint-Barthélemy",
    ssbCode: "687",
  },
  {
    name: "Argentina",
    ssbCode: "705",
  },
  {
    name: "Bolivia",
    ssbCode: "710",
  },
  {
    name: "Brasil",
    ssbCode: "715",
  },
  {
    name: "Guyana",
    ssbCode: "720",
  },
  {
    name: "Chile",
    ssbCode: "725",
  },
  {
    name: "Colombia",
    ssbCode: "730",
  },
  {
    name: "Ecuador",
    ssbCode: "735",
  },
  {
    name: "Falklandsøyene",
    ssbCode: "740",
  },
  {
    name: "Fransk Guyana",
    ssbCode: "745",
  },
  {
    name: "Paraguay",
    ssbCode: "755",
  },
  {
    name: "Peru",
    ssbCode: "760",
  },
  {
    name: "Surinam",
    ssbCode: "765",
  },
  {
    name: "Uruguay",
    ssbCode: "770",
  },
  {
    name: "Venezuela",
    ssbCode: "775",
  },
  {
    name: "Amerikansk Samoa",
    ssbCode: "802",
  },
  {
    name: "Australia",
    ssbCode: "805",
  },
  {
    name: "Salomonøyene",
    ssbCode: "806",
  },
  {
    name: "Christmasøya",
    ssbCode: "807",
  },
  {
    name: "Kokosøyene",
    ssbCode: "808",
  },
  {
    name: "Cookøyene",
    ssbCode: "809",
  },
  {
    name: "Fiji",
    ssbCode: "811",
  },
  {
    name: "Vanuatu",
    ssbCode: "812",
  },
  {
    name: "Tonga",
    ssbCode: "813",
  },
  {
    name: "Fransk Polynesia",
    ssbCode: "814",
  },
  {
    name: "Kiribati",
    ssbCode: "815",
  },
  {
    name: "Tuvalu",
    ssbCode: "816",
  },
  {
    name: "Guam",
    ssbCode: "817",
  },
  {
    name: "Nauru",
    ssbCode: "818",
  },
  {
    name: "USAs ytre småøyer",
    ssbCode: "819",
  },
  {
    name: "New Zealand",
    ssbCode: "820",
  },
  {
    name: "Niue",
    ssbCode: "821",
  },
  {
    name: "Norfolkøya",
    ssbCode: "822",
  },
  {
    name: "Mikronesiaføderasjonen",
    ssbCode: "826",
  },
  {
    name: "Papua Ny-Guinea",
    ssbCode: "827",
  },
  {
    name: "Pitcairn",
    ssbCode: "828",
  },
  {
    name: "Tokelau",
    ssbCode: "829",
  },
  {
    name: "Samoa",
    ssbCode: "830",
  },
  {
    name: "Wallis- og Futunaøyene",
    ssbCode: "832",
  },
  {
    name: "Ny-Caledonia",
    ssbCode: "833",
  },
  {
    name: "Marshalløyene",
    ssbCode: "835",
  },
  {
    name: "Palau",
    ssbCode: "839",
  },
  {
    name: "Nord-Marianene",
    ssbCode: "840",
  },
  {
    name: "Statsløs",
    ssbCode: "980",
  },
  {
    name: "Uoppgitt",
    ssbCode: "990",
  },
];
