
import { api } from "@/api/api";
import {
  ApiGetApplicationFormResponseDto,
  ApiGetApplicationFormTemplateDto,
  ApiGetCustomerDto,
  ApiGetMinSideCourseDto,
} from "@/api/generated/Api";
import CourseSectionHeader from "@/components/courses/section/CourseSectionHeader.vue";
import CourseSectionInfo from "@/components/courses/section/CourseSectionInfo.vue";
import CourseSectionParticipants from "@/components/courses/section/CourseSectionParticipants.vue";
import CourseSectionStaff from "@/components/courses/section/CourseSectionStaff.vue";
import CourseWebApplication from "@/components/courses/section/CourseWebApplication.vue";
import CourseSectionRefundList from "@/components/courses/section/CourseSectionRefund/CourseSectionRefundList.vue";
import BaseBreadCrumbs from "@/components/shared/breadcrumbs/BaseBreadCrumbs.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import { featureFlags } from "@/featureFlags";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { getSingleCourseStorageData } from "@/shared/helpers/courseStorageHelper";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import CourseSectionEmployer from "@/components/courses/section/CourseSectionEmployer/CourseSectionEmployer.vue";

export default defineComponent({
  name: "SingleCoursePage",
  components: {
    BaseBreadCrumbs,
    CourseSectionHeader,
    CourseSectionInfo,
    CourseSectionStaff,
    CourseSectionParticipants,
    BaseLayout,
    CourseWebApplication,
    CourseSectionRefundList,
    CourseSectionEmployer,
  },
  props: {
    routeTab: String,
  },
  setup(props) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const courseId = +route.params.id;
    const singleCourseStorageData = getSingleCourseStorageData(courseId);
    const userStatus = singleCourseStorageData.userStatus;
    const isVocationalSchool = computed(() => course.value?.mainCourseArea === "Fagskole");
    const course = ref<ApiGetMinSideCourseDto>();
    const contact = ref<ApiGetCustomerDto>();
    const applicationInfo = ref<ApiGetApplicationFormResponseDto>();
    const applicationForm = ref<ApiGetApplicationFormTemplateDto>();
    const userAppliedViaForm = singleCourseStorageData.appliedViaForm;

    const currentTab = computed({
      set: (tab: string) => {
        router.replace({ params: { tab } });
      },
      get: () => props.routeTab || "unread",
    });

    const showParticipantWebApplication = computed(
      () => applicationInfo.value && applicationForm.value && contact.value
    );

    const showRefundOption = computed(() => course.value?.economy?.allowExpenses);

    const getActiveUser = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        contact.value = (await api.minside.getCustomerPersonCurrentAsync()).data;
      });
    };

    const getCourse = async () => {
      course.value = (await api.minside.getCourseByIdAsync(courseId)).data;
    };

    async function setApplicationData() {
      if (!userAppliedViaForm) {
        return;
      }
      await globalLoadingWrapper({ blocking: true }, async () => {
        try {
          applicationInfo.value = (await api.course.getMyAppFormResponseAsync(+courseId)).data;
          applicationForm.value = (
            await api.appformtemplate.getAppFormTemplateAsync(applicationInfo.value?.templateId)
          ).data;
        } catch {
          openNotification(store, NotificationItemType.Error, "Feil under lesing av application form");
        }
      });
    }

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all([getActiveUser(), setApplicationData(), getCourse()]);
      });
    });

    return {
      isMobile,
      isVocationalSchool,
      course,
      courseId,
      showRefundOption,
      notInStagingOrProd: featureFlags.not_in_prod_or_staging,
      userStatus,
      currentTab,
      showParticipantWebApplication,
      contact,
      applicationInfo,
      applicationForm,
    };
  },
});
