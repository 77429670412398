var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": "Legg til arbeidsgiver",
      "modalType": _vm.modalType
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.submit
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Enhets-/virksomhetsnavn *",
      "counter": "100",
      "type": "text",
      "required": "",
      "rules": [_vm.validateNotEmpty]
    },
    model: {
      value: _vm.employerFormValues.employeerName,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employeerName", $$v);
      },
      expression: "employerFormValues.employeerName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Stillingsprosent",
      "type": "text",
      "required": "",
      "rules": [_vm.validateRange(0, 100)]
    },
    model: {
      value: _vm.employerFormValues.employmentFraction,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employmentFraction", $$v);
      },
      expression: "employerFormValues.employmentFraction"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-post til nærmeste overordnet",
      "type": "text",
      "required": "",
      "rules": [_vm.validateIsOptionalEmail]
    },
    model: {
      value: _vm.employerFormValues.employeerEmail,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employeerEmail", $$v);
      },
      expression: "employerFormValues.employeerEmail"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Mobilnummer til nærmeste overordnet",
      "type": "text",
      "required": "",
      "rules": [_vm.validateIsOptionalNorwegianPhoneNumber],
      "hint": "Husk landskode før nummeret! +47 om det er et norsk nummer"
    },
    model: {
      value: _vm.employerFormValues.employeerMobilePhone,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employeerMobilePhone", $$v);
      },
      expression: "employerFormValues.employeerMobilePhone"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Addresse",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.employerFormValues.employeerAddress,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employeerAddress", $$v);
      },
      expression: "employerFormValues.employeerAddress"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Poststed",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.employerFormValues.employeerCity,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employeerCity", $$v);
      },
      expression: "employerFormValues.employeerCity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Postnummer",
      "type": "text",
      "required": "",
      "rules": [_vm.validateIsOptionalNorwegianZipCode]
    },
    model: {
      value: _vm.employerFormValues.employeerPostalCode,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "employeerPostalCode", $$v);
      },
      expression: "employerFormValues.employeerPostalCode"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "Nåværende arbeidsgiver"
    },
    model: {
      value: _vm.employerFormValues.isCurrentEmployment,
      callback: function ($$v) {
        _vm.$set(_vm.employerFormValues, "isCurrentEmployment", $$v);
      },
      expression: "employerFormValues.isCurrentEmployment"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }