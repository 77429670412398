var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.deleteModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": "400px"
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteModalData
    },
    on: {
      "close": function ($event) {
        _vm.deleteModalData.showModal = false;
      },
      "submit": _vm.deleteRefundClaim
    }
  }, [_vm._v(" Er du sikker på at du vil slette refusjonskravet? ")])], 1) : _vm._e(), _c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('div', [_c('h3', {
    staticClass: "primary-header-color p-2"
  }, [_vm._v("Refusjonskrav")]), _c('v-card-subtitle', {
    staticClass: "pl-0"
  }, [_vm._v(" Her kan du se en oversikt over refusjonskrav som er knyttet til kurset. Dersom du ønsker å registrere et nytt refusjonskrav så trykker du på \"+\" tegnet til høyre. ")])], 1), _vm.canCreateRefund ? _c('v-btn', {
    attrs: {
      "small": _vm.isMobile,
      "color": "primary p-2",
      "fab": ""
    },
    on: {
      "click": function ($event) {
        return _vm.redirect();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e()], 1)])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "mt-2",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.refundClaims,
      "sort-by": "date",
      "sort-desc": ""
    },
    scopedSlots: _vm._u([{
      key: "header.id",
      fn: function (_ref) {
        var header = _ref.header;
        return [_c('span', {
          staticClass: "header-checkbox-text"
        }, [_vm._v(" " + _vm._s(header.text) + " ")]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "color": "info"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var on = _ref2.on,
                attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "color": "primary"
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Dette er et refereansenummer som vil følge med til utbetaling")])])];
      }
    }, {
      key: "item.description",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [value ? _c('v-tooltip', {
          attrs: {
            "open-on-click": _vm.isMobile,
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on,
                attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("mdi-message")])];
            }
          }], null, true)
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(value)
          }
        })]) : _vm._e()];
      }
    }, {
      key: "item.date",
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(_vm.formatDate(value)) + " ")];
      }
    }, {
      key: "item.status",
      fn: function (_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(value === "ordre opprettet" || value === "utkast" ? "Til utbetaling" : value) + " ")];
      }
    }, {
      key: "item.totalAmount",
      fn: function (_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.action",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var on = _ref9.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "data-cy": "editRefundButton",
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.redirect(item.id);
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.isDraft(item.status) || _vm.isRejected(item.status) ? "mdi-pencil" : "mdi-file-document-outline") + " ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.isDraft(item.status) || _vm.isRejected(item.status) ? "Rediger refusjonskrav" : "Se refusjonskrav"))])]), _vm.isDraft(item.status) || _vm.isRejected(item.status) ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref10) {
              var on = _ref10.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "data-cy": "deleteRefundButton",
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.showDeleteRefundModal(item);
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett refusjonskrav")])]) : _vm._e()];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }