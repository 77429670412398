
import { api } from "@/api/api";
import { ApiEmploymentInformationDto, ApiUpsertCourseParticipantEmploymentDto } from "@/api/generated/Api";
import BaseCard from "@/components/shared/BaseCard.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import {
  validateIsOptionalEmail,
  validateIsOptionalNorwegianPhoneNumber,
  validateIsOptionalNorwegianZipCode,
  validateNotEmpty,
  validateRange,
} from "@/shared/helpers/validationHelpers";
import { useRouter } from "@/shared/useHelpers";
import { PropType, defineComponent, ref } from "@vue/composition-api";

interface ApiEmploymentInformationDtoExtended extends ApiEmploymentInformationDto {
  employmentId: number;
}

export default defineComponent({
  name: "AddEmployerModal",
  components: { BaseModal, BaseCard, BaseModalForm },
  emits: ["getEmployers", "close"],
  props: {
    modalType: {
      type: String,
      default: ModalType.Add,
    },
    existingItem: {
      type: Object as PropType<ApiEmploymentInformationDtoExtended>,
      default: {},
      required: false,
    },
  },
  setup(props, { emit, refs }) {
    const initFormValues = (): ApiUpsertCourseParticipantEmploymentDto => {
      return {
        employeerName: props.existingItem.name || "",
        employeerEmail: props.existingItem.email || "",
        employeerMobilePhone: props.existingItem.mobilePhone || "",
        employeerAddress: props.existingItem.address || "",
        employeerPostalCode: props.existingItem.postalCode || "",
        employeerCity: props.existingItem.city || "",
        employmentFraction: props.existingItem.employmentFraction || 0,
        isCurrentEmployment: props.existingItem.isCurrentEmployment || false,
      };
    };

    const employerFormValues = ref<ApiUpsertCourseParticipantEmploymentDto>(initFormValues());
    const router = useRouter();

    const close = () => {
      emit("close");
    };
    const submit = async () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }

      const payload: ApiUpsertCourseParticipantEmploymentDto = {
        employeerName: employerFormValues.value.employeerName,
        employeerEmail: employerFormValues.value.employeerEmail,
        employeerMobilePhone: employerFormValues.value.employeerMobilePhone,
        employeerAddress: employerFormValues.value.employeerAddress,
        employeerCity: employerFormValues.value.employeerCity,
        employeerPostalCode: employerFormValues.value.employeerPostalCode,
        employmentFraction: employerFormValues.value.employmentFraction,
        isCurrentEmployment: employerFormValues.value.isCurrentEmployment,
      };

      props.modalType === ModalType.Edit
        ? await api.course.updateCourseParticipantEmployment(
            +router.currentRoute.params.id,
            props.existingItem.employmentId,
            payload
          )
        : await api.course.createCourseParticipantEmployment(+router.currentRoute.params.id, payload);

      emit("getEmployers");
      emit("close");
    };

    return {
      close,
      submit,
      employerFormValues,
      validateRange,
      validateNotEmpty,
      validateIsOptionalNorwegianPhoneNumber,
      validateIsOptionalNorwegianZipCode,
      validateIsOptionalEmail,
    };
  },
});
