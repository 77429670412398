
import { api } from "@/api/api";
import { ApiGetCourseParticipantTravelAndExpenseListDto } from "@/api/generated/Api";
import BaseDatePicker from "@/components/shared/date/BaseDatePicker.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseModal from "@/components/shared/modal/BaseModal.vue";
import router from "@/router/router";
import {
  EligibleCourseRefundStatus,
  EligibleUserRefundStatus,
  TravelAndExpenseStatus,
} from "@/shared/enums/courseRefund.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { getInitialModalData, useOpenModal } from "@/shared/helpers/modalHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSectionRefundList",
  components: {
    BaseModal,
    BaseConfirmModalForm,
    BaseDatePicker,
  },
  props: {
    courseId: {
      required: true,
      type: Number,
    },
    courseStatus: {
      required: true,
      type: String,
    },
  },
  setup({ courseId, courseStatus }) {
    const refundClaims = ref<ApiGetCourseParticipantTravelAndExpenseListDto[]>();
    const deleteModalData = ref(getInitialModalData());
    const route = useRoute();
    const userStatus = route.params.userStatus;
    const canCreateRefund =
      Object.values(EligibleUserRefundStatus).includes(userStatus.toLowerCase() as EligibleUserRefundStatus) &&
      Object.values(EligibleCourseRefundStatus).includes(courseStatus.toLowerCase() as EligibleCourseRefundStatus);

    onMounted(async () => {
      await getRefundClaims();
    });

    const getRefundClaims = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        refundClaims.value = (
          await api.travelAndExpense.getCourseParticipantTravelAndExpenseList({ CourseId: courseId })
        ).data;
      });
    };

    const redirect = (refundClaimId?: number) => {
      // Hack way to make user status available at the end of create refundclaim
      window.sessionStorage.setItem("participantStatus", userStatus);

      if (refundClaimId) {
        router.push("/course-refund/" + courseId + "/" + refundClaimId);
      } else {
        router.push("/course-refund/" + courseId);
      }
    };

    const deleteRefundClaim = async (modalData: ModalBaseData) => {
      const refundClaimId = modalData.existingItemId;
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.travelAndExpense.deleteCourseParticipantTravelAndExpense(refundClaimId);
        deleteModalData.value.showModal = false;
      });
      await getRefundClaims();
    };

    const headers = [
      { text: "Refusjonskravnummer", value: "id" },
      {
        text: "Handlinger",
        value: "action",
      },
      { text: "Opprettet dato", value: "date" },
      {
        text: "Beskrivelse",
        value: "description",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Beløp",
        value: "totalAmount",
      },
    ];

    return {
      formatCurrency,
      formatDate,
      redirect,
      headers,
      refundClaims,
      isDraft: (status: TravelAndExpenseStatus) => status === TravelAndExpenseStatus.Draft,
      isRejected: (status: TravelAndExpenseStatus) => status === TravelAndExpenseStatus.Rejected,
      showDeleteRefundModal: useOpenModal(ModalType.Delete, "refusjonskrav", deleteModalData),
      deleteRefundClaim,
      deleteModalData,
      isMobile,
      canCreateRefund,
    };
  },
});
