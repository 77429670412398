var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.course ? _c('BaseLayout', {
    attrs: {
      "display-tabs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.course.externalTitle))];
      },
      proxy: true
    }, !_vm.isMobile ? {
      key: "actions",
      fn: function () {
        return [_vm.course ? _c('CourseSectionHeader', {
          attrs: {
            "course": _vm.course,
            "userStatus": _vm.userStatus
          }
        }) : _vm._e()];
      },
      proxy: true
    } : null, {
      key: "tabs",
      fn: function () {
        return [_c('v-tabs', {
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v;
            },
            expression: "currentTab"
          }
        }, [_c('v-tab', {
          attrs: {
            "href": "#info"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-information")]), _vm._v(" " + _vm._s(_vm.isMobile ? "Info" : "Informasjon") + " ")], 1), _vm.showParticipantWebApplication ? _c('v-tab', {
          attrs: {
            "href": "#applicationForm"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-document-edit")]), _vm._v(" Påmeldingsskjema ")], 1) : _vm._e(), _vm.showRefundOption ? _c('v-tab', {
          attrs: {
            "href": "#refundForm"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-cash")]), _vm._v("Refusjonskrav")], 1) : _vm._e(), _vm.course.coursePractice ? _c('v-tab', {
          attrs: {
            "href": "#employer"
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-account-multiple")]), _vm._v("Arbeidsgiver")], 1) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  }, [[_vm.course && _vm.isMobile ? _c('CourseSectionHeader', {
    attrs: {
      "course": _vm.course,
      "userStatus": _vm.userStatus
    }
  }) : _vm._e(), _c('v-tabs-items', {
    attrs: {
      "value": _vm.currentTab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "info"
    }
  }, [_vm.course ? _c('CourseSectionInfo', {
    attrs: {
      "course": _vm.course,
      "isVocationalSchool": _vm.isVocationalSchool,
      "isResource": false
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "applicationForm"
    }
  }, [_vm.showParticipantWebApplication ? _c('CourseWebApplication', {
    attrs: {
      "contact": _vm.contact,
      "applicationInfo": _vm.applicationInfo,
      "applicationForm": _vm.applicationForm
    }
  }) : _vm._e()], 1), _c('v-tab-item', {
    attrs: {
      "value": "refundForm"
    }
  }, [_c('CourseSectionRefundList', {
    attrs: {
      "courseStatus": _vm.course.status,
      "courseId": _vm.courseId
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "employer"
    }
  }, [_c('CourseSectionEmployer')], 1)], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }