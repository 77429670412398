var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.inactivateModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": "400px"
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.inactivateModalData
    },
    on: {
      "close": function ($event) {
        _vm.inactivateModalData.showModal = false;
      },
      "submit": _vm.inactivateEmployers
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "red"
    }
  }, [_vm._v("mdi-flag")]), _vm._v(" Dette vil inaktivere alle dine arbeidsgivere! Er du sikker på dette? ")], 1)], 1) : _vm._e(), _vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('AddEmployerModal', {
    attrs: {
      "modalType": _vm.modalData.modalType
    },
    on: {
      "getEmployers": _vm.getEmployers,
      "close": _vm.closeModal,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.closeModal();
      }
    }
  })], 1) : _vm._e(), _vm.modalDataEdit.showModal ? _c('BaseModal', {
    on: {
      "close": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('AddEmployerModal', {
    attrs: {
      "modalType": _vm.modalDataEdit.modalType,
      "existingItem": _vm.modalDataEdit.existingItem
    },
    on: {
      "getEmployers": _vm.getEmployers,
      "close": _vm.closeModal
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('div', [_c('h3', {
    staticClass: "primary-header-color p-2"
  }, [_vm._v("Arbeidsgiver")]), _c('v-card-subtitle', {
    staticClass: "pl-0"
  }, [_vm._v(" Her kan du legge inn informasjon om arbeidsgiveren din. Trykk på + knappen til høyre for å gjøre det. ")])], 1), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "small": _vm.isMobile,
            "color": "primary p-2"
          },
          on: {
            "click": _vm.showInactivateEmployerModal
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Inaktiver")])];
      }
    }])
  }, [_c('span', [_vm._v("Inaktiver dine arbeidsgivere")])]), _c('v-btn', {
    attrs: {
      "small": _vm.isMobile,
      "color": "primary p-2",
      "fab": ""
    },
    on: {
      "click": _vm.openAddEmployer
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "mt-2",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.employers
    },
    scopedSlots: _vm._u([{
      key: "item.action",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.openEditEmployer(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }, {
      key: "item.employmentFraction",
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value + "%") + " ")];
      }
    }, {
      key: "item.isCurrentEmployment",
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }], null, true)
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }